.billad {
  height: auto;
  width: 100vw;
  display: flex;
  flex-direction: row;
}
.total {
  text-align: left;
}
.billad-left {
  width: 100%;
}
.Genrate {
  background-color: #6174dd;
}
.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background: #f7f7f7;
}
.list {
  background-color: #6174dd;
  color: #fff;
}
.table table-bordered {
  text-align: left;
}

.inner-table-space {
  margin-left: -20px;
}
.service {
  text-align: left;
}
#btnAdd {
  float: right;
}
.genrate {
  height: 50px;
  width: 100%;
  background-color: rgb(225, 218, 218);
}
.billad-right {
  width: 30%;
  margin-top: 20px;
  /* background-color: rgb(225, 218, 218); */
}
.billad-right h3 {
  height: 20%;
}
.btn-add {
  /*border-radius: 6px!important;*/
  padding: 6px 6px;
  margin-left: 100px;
  position: relative;
}

.btn-remove {
  /*border-radius: 6px!important;*/
  padding: 6px 6px;
  margin-left: 100px;
  position: relative;
}

/* css for small fields */
.qt,
.disc_row,
.disc_row_type {
  min-width: 70px;
}
.start_time,
.end_time {
  min-width: 85px;
}

.ui-autocomplete {
  z-index: 9999 !important;
}

.sub-tabs {
  background-color: #032f54;
  text-align: center;
  padding: 15px 0px;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
}
.sub-tabs:hover {
  background-color: #004782;
}
.sub-tabs.active {
  background-color: #be63de;
}
.sub-tabs.active:after {
  content: '';
  position: absolute;
  left: 40%;
  top: 50px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #be63de;
  clear: both;
}

@keyframes blinker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100%{
    opacity: 1;
  }
}

.blinking {
  animation: blinker 2s ease infinite;
}

.blinking:hover {
  opacity: 1; /* Ensure the div is fully visible when hovered over */
  animation: none;
  cursor: pointer;
}

.service-field{
  width: 10rem;
  padding: 0.5rem;
}
/* *************************GST INPUT CSS************************* */
/* GST Toggle Container Styling */
.tax-container {
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 8px;
  background: linear-gradient(120deg, #f9fafc, #e9ecef);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
}

/* GST Label Styling */
.gst-label {
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
  margin-right: 15px;
  transition: color 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.gst-label.gst-enabled {
  color: #28a745;
  transform: scale(1.1);
}

.gst-label.gst-disabled {
  color: #dc3545;
  transform: scale(1);
}

/* GST Toggle Switch Styling */
.gst-toggle {
  appearance: none;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.gst-toggle:checked {
  background-color: #007bff;
}

.gst-toggle:before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 50%;
  top: 1px;
  left: 1px;
  transition: transform 0.3s ease-in-out;
}

.gst-toggle:checked:before {
  transform: translateX(20px);
}

/* Tax Container Hover Effect */
.tax-container:hover {
  background: linear-gradient(120deg, #e9ecef, #f9fafc);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Centering the content in the table cell */
.col-md-4 {
  text-align: center;
}

/* Adjusted font size for the label */
.gst-label {
  font-size: 1rem;
}

