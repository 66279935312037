.container {
  display: flex;
  width: 50vw;
  height: 42vh;
  overflow: auto;
}

.container2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40vw;
  height: 42vh;
  overflow: auto;
}
.paymentBox {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.paymentBox div {
  margin-bottom: 15px;
}

.paymentBox label {
  font-weight: bold;
  margin-right: 10px;
}

.paymentBox input[type='number'],
.paymentBox select {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: 200px;
  box-sizing: border-box;
}

.paymentBox select {
  cursor: pointer;
}

.paymentBox input[type='number']:disabled {
  background-color: #f9f9f9;
  cursor: not-allowed;
}

.paymentBox select.paymentTypeSelect.border-danger {
  border-color: #dc3545 !important;
}

.paymentBox .text-danger {
  color: #dc3545;
  font-size: 14px;
}

.paymentBox button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.paymentBox button:hover {
  background-color: #0056b3;
}
