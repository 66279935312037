.print-body {
  border: 2px solid #808080;
}
.print-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 6px;
}
.middle-header{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.print-body-title {
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  text-transform: capitalize;
  font-weight: 700;
}
.print-body-text {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-weight: 500;
}
.print-title {
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}


.bill-body {
  border-top: 2px solid #808080;
  border-bottom: 2px solid #808080;
  display: flex;
  align-items: center;
}
.bill-body .bill-to {
  width: 50%;
  border-right: 2px solid #808080;
}
.bill-body .bill-invoice {
  width: 50%;
  text-align: right;
}
.bill-body .bill-to {
  width: 50%;
  border-right: 2px solid #808080;
}
.bill-to-box {
  background: #948fe3;
  padding: 4px 6px;
  text-transform: capitalize;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  border-bottom: 2px solid #808080;
}
.bill-to-box-title {
  font-size: 16px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  padding: 4px;
  margin-bottom: 4px;
}
.bill-to-box-text {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  padding: 4px;
  margin-bottom: 0px;
}
.print-table {
  width: 100%;
}
.print-table-thead {
  background: #948fe3;
  width: 100%;
}
.print-table-header th {
  border-right: 2px solid #808080;
  color: #fff;
  padding: 10px 6px;
  border-bottom: 2px solid #808080;
}
.print-table-body {
  width: 100%;
}
.print-table-body td {
  border-right: 2px solid #808080;
  color: #000;
  padding: 10px 6px;
  border-bottom: 2px solid #808080;
}

.gst-body {
  width: 100%;
  border-top: 2px solid #808080;
  border-bottom: 2px solid #808080;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.gst-body .gst-invoice {
  width: 50%;
  border-bottom: 2px solid #808080;
  border-right: 2px solid #808080;
  height: 100%;
}
.gst-body .gst-to {
  border: 2px solid #808080;
  width: 50%;
}
.gst-to-box {
  background: #948fe3;
  padding: 4px 6px;
  text-transform: capitalize;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  margin: 0;
}
.gst-to-box-title {
  font-size: 16px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  padding: 4px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.gst-to-box-title .gst-total {
  font-size: 16px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
}
.gst-to-box-text {
  padding: 4px;
  margin-bottom: 0px;
  border-bottom: 2px solid #808080;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.gst-to-box-text .gst-sub-total {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}

.gst-table-thead {
  background-color: #948fe3;
}
.gst-table-thead tr th {
  text-transform: capitalize;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  padding: 4px 6px;
}
.gst-table-body td {
  font-weight: 500;
  color: #000;
  padding-bottom: 10px;
  font-size: 14px;
}

.right-table {
  text-align: right;
}

.amount-container {
  border-top: 2px solid #808080;
  border-bottom: 2px solid #808080;
}
.amount-container .amount-body {
  width: 50%;
  border-right: 2px solid #808080;
}
.amount-body .amount-text {
  font-weight: 500;
  color: #000;
  padding-bottom: 10px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 0;
  padding: 6px 0;
}

.terms-container {
  display: flex;
}
.terms-body {
  width: 50%;
  border-right: 2px solid #808080;
}
.terms-body .terms-text {
  font-weight: 500;
  color: #000;
  padding-bottom: 10px;
  font-size: 16px;
  margin-bottom: 0;
  padding: 6px;
}
.terms-body-1 {
  width: 50%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.terms-body-1 .terms-text {
  font-weight: 500;
  color: #000;
  padding-bottom: 10px;
  font-size: 16px;
  margin-bottom: 0;
  padding: 6px;
}
a{
  text-decoration: none;
}