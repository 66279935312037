/* PackagePage.css */
.package-container {
    padding: 20px;
}
.package-title h2{
    font-weight: 700;
    
}
.packageTable {
    width:60%;
    border-collapse: collapse;
}
.packageTable tbody{
    width: 100%;
}
.packageTable tbody tr{
    border: 1px solid #CFCFCF;
    border-radius: 8px;
    background-color: #FAFAFA;
    padding: 6px 12px;
    margin-bottom: 8px;    
}

.packageTable th,
.packageTable td {
    padding: 5px;   
    /* text-align: left; */
}

.packageTable th {
    background-color:#6174DD;
    color: white;
}

.packageTable tr.selected {
    background-color: #e0f7fa;
}

.create-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.create-btn:hover {
    background-color: #0056b3;
}

.dialog-paper {
    max-width: 500px; /* Increase the max-width as needed */
}

.dialog-content {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Add gaps between the elements */
}

.service-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    align-items: center;
    gap: 40px;
}

.heading-h6{
    display: flex;
    justify-content: space-between;
}

.heading-h6 h6{
    font-weight: 800;
    font-size: 18px;
}

.input-checkbox {
    /* width: 20px;
    height: 20px; */
    margin-top: 6px;
    margin-left: 12px;
    width: 16px;
    height: 16px;
    margin-right: 16px;
  }
  .input-checkbox {
    transform: scale(1.2);
}
