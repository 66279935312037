.bill-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.printPackage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.Packagebranch-logo {
    width: 50px;
    height: 50px;
}

.header-package-text h6 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
}

.header-package-text span {
    font-size: 12px;
    color: #666;
}

.Package-bill-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.Package-client-info,
.invoice-info {
    font-size: 14px;
    line-height: 1.6;
}

.Package-print-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.Package-print-table th {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
    background: #f9f9f9;
    font-size: 14px;
    font-weight: bold;
}

.Package-print-table td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
    font-size: 14px;
}

.Package-print-table tr:nth-child(even) {
    background: #f9f9f9;
}

.Package-print-table tr:hover {
    background: #f1f1f1;
}

.additional-services {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
}

.additional-services h6 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
}

.additional-services ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.additional-services li {
    padding: 5px 0;
}

.Package-amount-summary {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #333;
}

.Package-action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.Package-download-button,
.Package-print-button {
    width: 150px;
    font-size: 14px;
    padding: 10px;
    border-radius: 4px;
    font-weight: bold;
}
