.custom-tooltip {
    padding: 10px;
    border: 1px solid #ccc;
    background: #fff;
    font-size: 12px;
}

.custom-tooltip table {
    width: 100%;
    border-collapse: collapse;
}

.custom-tooltip th, .custom-tooltip td {
    border: 1px solid #ddd;
    padding: 5px;
    text-align: left;
}

.custom-tooltip th {
    background-color: #f2f2f2;
}