.owner-header {
  display: flex;
  align-items: center;
  gap: 20px;
}
.owner-header .profile-img {
  height: 100px;
  width: 100px;
  background-color: #c1c6c9;
  border-radius: 50%;
}
.owner-header .owner-text {
  color: #707070;
  font-weight: 500;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  margin: 0;
}
.owner-header .owner-bold-title {
  color: #707070;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  margin: 0;
}
.owner-header .owner-title {
  color: #707070;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  margin: 0;
}
.owner-body {
  margin-top: 20px;
}
.owner-body .owner-text {
  color: #707070;
  font-weight: 500;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  margin: 0;
}
.owner-body .owner-title {
  color: #707070;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  margin: 0;
}
