.gst-table-container{
    width: 100%;
    /* width: 168%; */
    box-shadow: 2px 2px 5px gray;
    padding: 5px;
}
.gst-table-container table{
    /* width: 100%; */
    width: 100%;
  
}
.gst-table-container table th{
    padding: 10px;
    text-align: center;
    background-color: rgb(219, 224, 228);
}
.gst-table-container table td{
    padding: 8px;
    text-align: center;
    color: rgb(51, 53, 55)
}
.download-pdf{
    margin-top: 20px;
    text-align: center;
}
.download-pdf button{
    padding: 10px 20px;
    background-color: rgb(101, 101, 209);
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 1.2rem;
    font-weight: 700;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.gstbill-btn{
    margin-top: 20px;
}
.gstbill-btn button{
    padding: 10px 20px;
    background-color: rgb(101, 101, 209);
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.dateInput-container{
    margin-top: 2rem;
    /* width: 168%; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0rem;
    background-color: #FAFAFA;
}