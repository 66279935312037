.pdf-container {
    padding: 20px;
    border: 1px solid #000;
    width: 75%;
    margin: auto;
    font-size: 14px; /* Default font size */
}

.pdf-container h3 {
    font-size: 18px !important;
    font-weight: bold;
}

.pdf-container table th {
    font-size: 16px;
    font-weight: bold;
}
